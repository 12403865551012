'use client';

import { Link } from '@salesport/ui-components';

import routes from '@/routes';

export const AlreadyHaveAccountLink = () => {
  return (
    <p className="text-center text-small">
      Already have an account?&nbsp;
      <Link href={routes.auth.build('signin')} size="sm">
        Log In
      </Link>
    </p>
  );
};
