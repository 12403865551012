'use client';

import { useState } from 'react';

import { Button, Input } from '@salesport/ui-components';

import { handleRequest } from '@/common/utils/auth-helpers/client';
import { requestPasswordUpdate } from '@/common/utils/auth-helpers/server';
import { AlreadyHaveAccountLink } from './AlreadyHaveAccountLink';
import { CreateAccountLink } from './CreateAccountLink';

type ForgotPasswordProps = {
  emailSent: boolean;
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  emailSent,
}) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(emailSent);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    await handleRequest(e, requestPasswordUpdate);
    setIsSubmitting(false);
  };

  return (
    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
      <div className="w-full text-left">
        <p className="pb-2 text-xl font-medium">Forgot password?</p>
        <p className="text-small text-default-500">
          Reset your password by entering your email address
        </p>
      </div>

      <form className="flex w-full flex-col gap-3" onSubmit={handleSubmit}>
        <Input
          label="Email Address"
          name="email"
          placeholder="Enter your email"
          type="email"
          variant="underlined"
          onChange={() => setIsBtnDisabled(false)}
        />
        <Button
          isDisabled={emailSent}
          isLoading={isSubmitting}
          color="primary"
          type="submit">
          {isBtnDisabled ? 'Email has been sent!' : 'Reset Password'}
        </Button>
      </form>

      <AlreadyHaveAccountLink />
      <CreateAccountLink />
    </div>
  );
};
