'use client';

import { Eye, EyeSlash } from 'iconsax-react';
import { Button, Checkbox, Input, Link } from '@salesport/ui-components';

import { clientEnv } from '@/env/client-env';
import { AlreadyHaveAccountLink } from './AlreadyHaveAccountLink';
import { OAuthSignIn } from './OAuthSignIn';
import { useSignUpForm } from './useSignUpForm';

export const SignUp = () => {
  const {
    formData,
    formErrors,
    handleChange,
    canSubmit,
    handleSubmit,
    isSubmitting,
    isPassVisible,
    togglePassVisibility,
  } = useSignUpForm();

  return (
    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
      <div className="w-full text-left">
        <p className="pb-2 text-xl font-medium">Create Account</p>
        <p className="text-small text-default-500">
          Sign up for a new account to get started
        </p>
      </div>

      <OAuthSignIn />

      <form className="flex w-full flex-col gap-3" onSubmit={handleSubmit}>
        <Input
          isRequired
          label="Email Address"
          name="email"
          placeholder="Enter your email"
          type="email"
          variant="underlined"
          value={formData.email}
          isInvalid={formErrors.email != null}
          errorMessage={formErrors.email}
          onChange={handleChange}
        />
        <Input
          isRequired
          endContent={
            <button type="button" onClick={togglePassVisibility}>
              {isPassVisible ? (
                <EyeSlash className="pointer-events-none text-2xl text-default-400" />
              ) : (
                <Eye className="pointer-events-none text-2xl text-default-400" />
              )}
            </button>
          }
          label="Password"
          name="password"
          placeholder="Create a password"
          type={isPassVisible ? 'text' : 'password'}
          variant="underlined"
          value={formData.password}
          isInvalid={formErrors.password != null}
          errorMessage={formErrors.password}
          onChange={handleChange}
        />
        <Input
          isRequired
          label="Confirm Password"
          name="confirmPassword"
          placeholder="Confirm your password"
          type={isPassVisible ? 'text' : 'password'}
          variant="underlined"
          value={formData.confirmPassword}
          isInvalid={formErrors.confirmPassword != null}
          errorMessage={formErrors.confirmPassword}
          onChange={handleChange}
        />
        <Checkbox
          isRequired
          name="termsAndConditions"
          className="py-4"
          size="sm"
          onChange={handleChange}>
          I agree with the&nbsp;
          <Link href={clientEnv.spTermsUrl} target="_blank" size="sm">
            Terms
          </Link>
          &nbsp; and&nbsp;
          <Link href={clientEnv.spPrivacyUrl} target="_blank" size="sm">
            Privacy Policy
          </Link>
        </Checkbox>
        <Button
          color="primary"
          type="submit"
          isDisabled={!canSubmit}
          isLoading={isSubmitting}>
          Sign Up
        </Button>
      </form>

      <AlreadyHaveAccountLink />
    </div>
  );
};
