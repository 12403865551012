'use client';

import { useState } from 'react';

import { EyeSlash } from 'iconsax-react';
import { Eye } from 'lucide-react';
import { Button, Input, Link } from '@salesport/ui-components';

import { handleRequest } from '@/common/utils/auth-helpers/client';
import { signInWithPassword } from '@/common/utils/auth-helpers/server';
import routes from '@/routes';
import { CreateAccountLink } from './CreateAccountLink';
import { OAuthSignIn } from './OAuthSignIn';

export const PasswordSignIn = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    await handleRequest(e, signInWithPassword);
    setIsSubmitting(false);
  };

  return (
    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
      <div className="w-full text-left">
        <p className="pb-2 text-xl font-medium">Welcome Back</p>
        <p className="text-small text-default-500">
          Log in to your account to continue
        </p>
      </div>

      <OAuthSignIn />

      <form className="flex w-full flex-col gap-3" onSubmit={handleSubmit}>
        <Input
          label="Email Address"
          name="email"
          placeholder="Enter your email"
          type="email"
          variant="underlined"
        />
        <Input
          endContent={
            <button type="button" onClick={toggleVisibility}>
              {isVisible ? (
                <EyeSlash className="pointer-events-none text-2xl text-default-400" />
              ) : (
                <Eye className="pointer-events-none text-2xl text-default-400" />
              )}
            </button>
          }
          label="Password"
          name="password"
          placeholder="Enter your password"
          type={isVisible ? 'text' : 'password'}
          variant="underlined"
        />
        <div className="flex items-center justify-end px-1 py-2">
          <Link
            className="text-default-500"
            href={routes.auth.build('forgot-password')}
            size="sm">
            Forgot password?
          </Link>
        </div>
        <Button isLoading={isSubmitting} color="primary" type="submit">
          Log In
        </Button>
      </form>

      <CreateAccountLink />
    </div>
  );
};
