export const Google = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3006 10.0415H21.4951V10H12.4951V14H18.1466C17.3221 16.3285 15.1066 18 12.4951 18C9.18162 18 6.49512 15.3135 6.49512 12C6.49512 8.6865 9.18162 6 12.4951 6C14.0246 6 15.4161 6.577 16.4756 7.5195L19.3041 4.691C17.5181 3.0265 15.1291 2 12.4951 2C6.97262 2 2.49512 6.4775 2.49512 12C2.49512 17.5225 6.97262 22 12.4951 22C18.0176 22 22.4951 17.5225 22.4951 12C22.4951 11.3295 22.4261 10.675 22.3006 10.0415Z"
        fill="#FFC107"
      />
      <path
        d="M3.64819 7.3455L6.93369 9.755C7.82269 7.554 9.97569 6 12.4952 6C14.0247 6 15.4162 6.577 16.4757 7.5195L19.3042 4.691C17.5182 3.0265 15.1292 2 12.4952 2C8.65419 2 5.32319 4.1685 3.64819 7.3455Z"
        fill="#FF3D00"
      />
      <path
        d="M12.495 22C15.078 22 17.425 21.0115 19.1995 19.404L16.1045 16.785C15.0668 17.5742 13.7988 18.001 12.495 18C9.89403 18 7.68553 16.3415 6.85353 14.027L3.59253 16.5395C5.24753 19.778 8.60853 22 12.495 22Z"
        fill="#4CAF50"
      />
      <path
        d="M22.3006 10.0415H21.4951V10H12.4951V14H18.1466C17.7522 15.1082 17.0418 16.0766 16.1031 16.7855L16.1046 16.7845L19.1996 19.4035C18.9806 19.6025 22.4951 17 22.4951 12C22.4951 11.3295 22.4261 10.675 22.3006 10.0415Z"
        fill="#1976D2"
      />
    </svg>
  );
};
