import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/AlreadyHaveAccountLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/CreateAccountLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/ForgotPassword.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/OAuthSignIn.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/PasswordSignIn.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/SignUp.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/UpdatePassword.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/useSignUpForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/platform/features/auth/components/useUpdatePasswordForm.tsx");
