'use client';

import { useState } from 'react';

import { type Provider } from '@supabase/supabase-js';
import { Button, Divider } from '@salesport/ui-components';

import { Google } from '@/common/components/icons/Google';
import { Microsoft } from '@/common/components/icons/Microsoft';
import { signInWithOAuth } from '@/common/utils/auth-helpers/client';

type OAuthProviders = {
  /**
   * Corresponds to the provider name in the Supabase auth settings
   *
   * See https://supabase.com/docs/guides/auth/social-login
   */
  name: Provider;
  displayName: string;
  icon: JSX.Element;
};

const oAuthProviders: OAuthProviders[] = [
  {
    name: 'google',
    displayName: 'Continue with Google',
    icon: <Google />,
  },
  // Temporary disabling Azure auth due to the issue below
  // https://github.com/supabase/auth/issues/550#issuecomment-1299111066
  {
    name: 'azure',
    displayName: 'Continue with Microsoft',
    icon: <Microsoft />,
  },
];

export const OAuthSignIn = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    await signInWithOAuth(e);
    setIsSubmitting(false);
  };

  if (oAuthProviders.length === 0) return null;

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        {oAuthProviders.map((provider) => (
          <form key={provider.name} onSubmit={(e) => handleSubmit(e)}>
            <input type="hidden" name="provider" value={provider.name} />
            <Button
              fullWidth
              startContent={provider.icon}
              variant="bordered"
              type="submit"
              isLoading={isSubmitting}>
              {provider.displayName}
            </Button>
          </form>
        ))}
      </div>
      <div className="flex w-full items-center gap-4 py-2">
        <Divider className="flex-1" />
        <p className="shrink-0 text-tiny text-default-500">OR</p>
        <Divider className="flex-1" />
      </div>
    </>
  );
};
