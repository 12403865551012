'use client';

import { Eye, EyeSlash } from 'iconsax-react';
import { Button, Input } from '@salesport/ui-components';

import { useUpdatePasswordForm } from './useUpdatePasswordForm';

export const UpdatePassword = () => {
  const {
    formData,
    formErrors,
    handleSubmit,
    canSubmit,
    isSubmitting,
    handleChange,
    isPassVisible,
    togglePassVisibility,
  } = useUpdatePasswordForm();

  return (
    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
      <div className="w-full text-left">
        <p className="pb-2 text-xl font-medium">Update password</p>
        <p className="text-small text-default-500">
          Update your password by entering a new one
        </p>
      </div>

      <form className="flex w-full flex-col gap-3" onSubmit={handleSubmit}>
        <Input
          isRequired
          endContent={
            <button type="button" onClick={togglePassVisibility}>
              {isPassVisible ? (
                <EyeSlash className="pointer-events-none text-2xl text-default-400" />
              ) : (
                <Eye className="pointer-events-none text-2xl text-default-400" />
              )}
            </button>
          }
          label="Password"
          name="password"
          placeholder="Create a password"
          type={isPassVisible ? 'text' : 'password'}
          variant="underlined"
          value={formData.password}
          isInvalid={formErrors.password != null}
          errorMessage={formErrors.password}
          onChange={handleChange}
        />
        <Input
          isRequired
          label="Confirm Password"
          name="confirmPassword"
          placeholder="Confirm your password"
          type={isPassVisible ? 'text' : 'password'}
          variant="underlined"
          value={formData.confirmPassword}
          isInvalid={formErrors.confirmPassword != null}
          errorMessage={formErrors.confirmPassword}
          onChange={handleChange}
        />
        <Button
          color="primary"
          type="submit"
          isDisabled={!canSubmit}
          isLoading={isSubmitting}>
          Update password
        </Button>
      </form>
    </div>
  );
};
