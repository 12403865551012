'use client';

import { Link } from '@salesport/ui-components';

import routes from '@/routes';

export const CreateAccountLink = () => {
  return (
    <p className="text-center text-small">
      Need to create an account?&nbsp;
      <Link href={routes.auth.build('signup')} size="sm">
        Sign Up
      </Link>
    </p>
  );
};
