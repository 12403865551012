'use client';

import { useState } from 'react';

import { handleRequest } from '@/common/utils/auth-helpers/client';
import { updatePassword } from '@/common/utils/auth-helpers/server';
import {
  validateConfirmPassword,
  validatePassword,
} from '@/common/utils/auth-helpers/validators';

type FormData = {
  password: string;
  confirmPassword: string;
};

type FormErrors = Record<keyof FormData, string | null>;

export function useUpdatePasswordForm() {
  const [validationEnabled, setValidationEnabled] = useState(false);

  const [isPassVisible, setIsPassVisible] = useState(false);
  const togglePassVisibility = () => setIsPassVisible(!isPassVisible);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setValidationEnabled(true);
    const formErrors = validateForm(formData);
    if (!isFormValid(formErrors)) {
      setFormErrors(formErrors);
      return;
    }

    setIsSubmitting(true);
    await handleRequest(e, updatePassword);
    setIsSubmitting(false);
  };

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    password: null,
    confirmPassword: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };
    setFormData(newFormData);
    if (validationEnabled) {
      setFormErrors(validateForm(newFormData));
    }
  };
  const canSubmit = isFormValid(formErrors);

  return {
    formData,
    formErrors,
    handleChange,
    isPassVisible,
    togglePassVisibility,
    isSubmitting,
    canSubmit,
    handleSubmit,
  };
}

function validateForm(data: FormData): FormErrors {
  return {
    password: validatePassword(data.password),
    confirmPassword: validateConfirmPassword(
      data.password,
      data.confirmPassword,
    ),
  };
}

function isFormValid(errors: FormErrors): boolean {
  return Object.values(errors).every((error) => error == null);
}
